import Vue from 'vue'

export default{
  namespaced: true,
  state:{
    ordenes:'',
  },

  mutations:{
    //actualiza los articulos con la informacion que le manda el action
    actualizaOrdenes(state, ordenes){
      state.logeado = ordenes
    },
  },

  actions:{
		
    //ejecuta la api y llama a la mutcion traeInfo para poder mandarle la información obtenida de la api
    ejecutaEmailPass({commit}){
      Vue.http.get('auth/api/public/ordenes')
        .then(function(response){
          this.someData = response.body
          commit('actualizaOrdenes', response.body)

        }).catch(function(error){
          console.log(error)
        })
    }
  },

  getters:{
    //trae la información de ordenes despues de haber sido mutada
    traeOrdenes(state){
      return state.logeado
    },
  }
}