
export default{
  namespaced: true,
  state:{
    controles: {
      fecha1: new Date().toISOString().substr(0, 10),
      fecha2: new Date().toISOString().substr(0, 10),
      idcontacto:0,
      idproy:0,
      idweb:0,
    },
    drawer2: false
  },

  mutations:{
    SET_CONTROLES (state, controles){
      state.controles = controles
    },

    SET_DRAWER2 (state, drawer2){
      state.drawer2 = drawer2
    }
  },

  actions:{
    setControles ({commit}, controles){
      commit('SET_CONTROLES', controles)
    },

    setDrawer2 ({commit}, drawer2){
      commit('SET_DRAWER2', drawer2)
    },

  },

  getters:{
    getControles(state){
      return state.controles
    },

    getDrawer2(state){
      return state.drawer2
    },


  }
}