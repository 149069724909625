import Vue    from 'vue'
// import Vuex  from 'vuex'
import router from '@/router'

export default{

  namespaced: true,
  state:{
    imagen: '',
  },

  mutations:{

    SET_IMAGEN(state, imagen){
      state.imagen = imagen
    }
  },

  actions:{
    setImagen({commit}, imagen){
      commit('SET_IMAGEN', imagen)
    },

    //agrega una nueva orden
    addOrden( neworden){
      return new Promise((resolve) => {
        Vue.http.post('auth/api/v1/ordenes',neworden).then(()=>{
          resolve(true)
          router.push({path: 'misordenes'})
        })
      })
    },
  },

  getters:{
    getImagen(state){
      return state.imagen
    }
    
  }
}
////////////////