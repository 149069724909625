export default {
  namespaced: true,
  
  state: {
    proyecto: {
      nomproy: "",
      monto:"",
      idcontacto:"",
      departamento: ""
    }
  },
    
  mutations: {
    SET_PROYECTO (state, proyecto){
      state.proyecto = proyecto
    },

    SET_NEW_PROYECTO (state, nomproy){
      state.proyecto.nomproy = nomproy
    },

  },
  
  actions: {
    setProyecto ({ commit }, proyecto) {
      commit("SET_PROYECTO", proyecto);
    },

    setNewProyecto ({ commit }, nomproy) {
      commit("SET_NEW_PROYECTO", nomproy);
    },


    setLimpiarProyecto({ commit}){
      // console.log("LIMPIAR LEEproyecto")
      let proyecto = {
        nomproy: "",
        monto:"",
        idcontacto:"",
        departamento: ""
      }

      commit ("SET_PROYECTO", proyecto)
    }


  },


  getters:{
    getProyecto ( state) {
      return state.proyecto
    },
    getNewProyecto ( state) {
      return state.proyecto.nomproy
    }


  }


};