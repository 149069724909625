export default {
  namespaced: true,
  
  state: {
    contacto: {
      nomcont: "",
      idcontacto: "",
      numcont: "",
      empresa: "",
      telefono: "",
      numcli: "",
      email: "",
      celular: "",
      sucursal: "",
      departamento: ""
    }
  },
    
  mutations: {
    SET_CONTACTO (state, contacto){
      state.contacto = contacto
    },

    SET_NEW_CONTACTO (state, nomcont){
      state.contacto.nomcont = nomcont
    },

  },
  
  actions: {
    setContacto ({ commit }, contacto) {
      commit("SET_CONTACTO", contacto);
    },

    setNewContacto ({ commit }, nomcont) {
      commit("SET_NEW_CONTACTO", nomcont);
    },


    setLimpiarContacto({ commit}){
      // console.log("LIMPIAR LEECONTACTO")
      let contacto = {
        nomcont: "",
        idcontacto: "",
        numcont: "",
        empresa: "",
        telefono: "",
        numcli: "",
        email: "",
        celular: "",
        sucursal: "",
        departamento: ""
      }

      commit ("SET_CONTACTO", contacto)
    }


  },


  getters:{
    getContacto ( state) {
      return state.contacto
    },
    getNewContacto ( state) {
      return state.contacto.nomcont
    }



  }


};