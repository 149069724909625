import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'
import vuetify from './plugins/vuetify';

import * as VueGoogleMaps from 'vue2-google-maps'

import VueResource from 'vue-resource'
Vue.use(VueResource)


//https://www.npmjs.com/package/vue2-google-maps
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBFUkiHpBK_B4SZN1HrLlyC_2eQ1sKSYZg',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

//IMPORTANDO VUE JSON EXCEL
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false

if (process.env.NODE_ENV=="development"){
  Vue.http.options.root = 'https://soportesait.com/api/'
  //Vue.http.options.root = 'http://localhost:8094/api'
  
  //Vue.http.options.root = 'http://localhost:8088/api'
  
// }
} else {
  Vue.http.options.root = 'https://soportesait.com/api/'
  // Vue.http.options.root = process.env.VUE_APP_OPTIONS_ROOT
}


Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', 'Bearer '+ localStorage.soporte)
  request.headers.set('Accept', 'application/json')
  next()
});

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
