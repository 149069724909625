import Vue from 'vue'

export default{
  namespaced: true,
  state:{
    registro:''
  },

  mutations:{

    ACTUALIZREGISTRO(state, registro){
      state.registro = registro
    }

  },

  actions:{

    // BUSCAR USUARIO
    buscarUsuarioWeb(state, usuarionew){
      let FormData = {'Email': usuarionew.email}
      console.log(FormData)
      console.log(usuarionew)

      return new Promise((resolve) => {
        
        
        Vue.http.post('getidxmail',  FormData).then(respuesta=>{
          return respuesta.json()
        }).then(respuestaJson=>{
          console.log(respuestaJson)
          // VALIDO LA RESPUESTA

          if(respuestaJson == "Sin resultados"){
            // SI NO ENCUENTRA AL USUARIO MANDA A REGISTRARLO
            resolve(true)

          }else{
            // SI ENCUENTRO AL USUARIO REGRESO FALSO
            resolve(false)
          }

        }).catch(error=>{console.log('error',error)})
      })
    },

    registrarUsuario (state, usuario){
      return new Promise((resolve, reject) => {
				
        const payload = {
          Nomuser:      usuario.nomuser,
          Email:        usuario.email,
          Password:     usuario.password,
          Idnivel :     '3',
          Estatus: 	    "1",
          Nivel:        "USER",
          Telefono:     "",
          Empresa:      "",
          Depto:        "",
          Suc:          "",
        }

        console.log(payload)
        Vue.http.post('usuariosweb',  payload)
          .then(respuesta=>{ return respuesta.json() })
          .then(respuestaJson=>{
            console.log(respuestaJson)
            // VALIDO LA RESPUESTA

            if(respuestaJson.error ==null){

              resolve(true)

            }else{
              resolve(false)
            }

          }).catch(error=>{
            reject(error)
            console.log('error',error)})
      })
    },
  },

  getters:{
		
    traerDatosUsuarios(state){
      return state.registro
    }

  }
}

