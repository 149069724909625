export default {
  namespaced: true,
  
  state: {
    cliente: {
      numcli: "",
      idweb: "",
      nomcli: "",
      nomcomer:'',
      rfc:"",

    }
  },
    
  mutations: {
    SET_CLIENTE (state, cliente){
      state.cliente = cliente
    },

    SET_NEW_CLIENTE (state, nomcli){
      state.cliente.nomcli = nomcli
    },

  },
  
  actions: {
    setCliente ({ commit }, cliente) {
      commit("SET_CLIENTE", cliente);
    },

    setNewCliente ({ commit }, nomcli) {
      commit("SET_NEW_CLIENTE", nomcli);
    },

    setLimpiarCliente({ commit}){
      console.log("setLimpiarCliente")
      let cliente = {
        numcli: "",
        idweb: "",
        nomcli: "",
        nomcomer:'',
        rfc:'',
      }

      commit ("SET_CLIENTE", cliente)
    }


  },

  getters:{
    getCliente ( state) {
      return state.cliente
    },
    getNewCliente ( state) {
      return state.cliente.nomcli
    }

  }

};