import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);



export default new Vuetify({

  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        background: '78909C',
        primary: colors.blue.darken2,
        secondary: colors.lightBlue.darken1,
        accent:  colors.orange,
        success: colors.blue,
        info:    colors.orange.darken2,
        error:   colors.red.darken2
      },
    },
  },
});
