import Vue       from 'vue'
import VueRouter from 'vue-router'
import Home      from '@/views/Home.vue'
import store from '@/store'

/* eslint-disable */

Vue.use(VueRouter)

  const routes = [
    //LOGIN
    { path: '/',  name: 'login', component:()=> import('@/views/Login/Login.vue'), 
      meta: { libre: true, usuarios: true  }},

    { path: '/olvidacontra', name: 'olvidacontra',        component:()=> import('@/views/Login/OlvidaContra.vue') ,
      meta: { libre: true, usuarios: true }} ,
    { path: '/cambiacontra/:id'  , name: 'cambiacontra',  component:()=> import('@/views/Login/CambiaContra.vue') ,
      meta: { libre: true, usuarios: true }},

    {path: '/registro',  name: 'registro',                component:()=> import('@/views/Login/Registro.vue'),
      meta: { libre: true, usuarios: true }},

    {path: '/activarusuario/:id', name: 'activarusuario',   component:()=> import('@/views/Login/ActivarUsuario'),
      meta: { libre: true}},

    {path: '/miperfil', name: 'miperfil', component:()=> import('@/views/Login/MyPerfil.vue'),
      meta: { usuarios: true }},
    
    
    // //Acciones generales para los usuarios web y 

    // {path: '/newordenuser',     name: 'newordenuser',    component: ()=> import('@/views/ordenesUser/NuevaOrdenUser.vue'),
    //   meta: { usuarios: true  }},
    // {path: '/verordenuser', name: 'verordenuser',        component: ()=> import('@/views/ordenesUser/VerOrdenUser.vue'),
    //   meta: { usuarios: true  }},
    // {path: '/misordenes',   name: 'misordenes',          component: () => import('@/views/ordenesUser/MisOrdenes.vue'),
    //   meta: { usuarios: true }},

    {path: '/addorden',     name: 'addorden',    component: ()=> import('@/views/crm/ordenes/addorden'),
      meta: { usuarios: true  }},
  
    {path: '/misordenes',     name: 'misordenes',    component: ()=> import('@/views/crm/ordenes/misordenes.vue'),
      meta: { usuarios: true  }},
    
    //ASESORES
    {path: '/conordenes',     name: 'conordenes',    component: ()=> import('@/views/crm/ordenes/conordenes.vue'),
    meta: { usuarios: true  }},
    // {path: '/regservicio',     name: 'regservicio',    component: ()=> import('@/views/asesores/regservicio.vue'),
    // meta: { usuarios: true  }},

    // GRAFICAS
    {path: '/graficaact',     name: 'graficaact',    component: ()=> import('@/views/graficas/graficaact.vue'),
    meta: { usuarios: true  }},
    {path: '/graficacitas',     name: 'graficacitas',    component: ()=> import('@/views/graficas/graficacitas.vue'),
    meta: { usuarios: true  }},
    {path: '/graficaopo',     name: 'graficaopo',    component: ()=> import('@/views/graficas/graficaopo.vue'),
    meta: { usuarios: true  }},
    {path: '/graficas',     name: 'graficas',    component: ()=> import('@/views/graficas/graficas.vue'),
    meta: { usuarios: true  }},
    {path: '/graficarordenes',     name: 'graficarordenes',    component: ()=> import('@/views/graficas/graficarordenes.vue'),
    meta: { usuarios: true  }},
  
    {path: '/ordxmes',     name: 'ordxmes',    component: ()=> import('@/views/graficas/ordxmes.vue'),
      meta: { usuarios: true  }},

    //CRM
    //PANEL
    {path: '/panel',     name: 'panel',    component: ()=> import('@/views/crm/panel.vue'),
    meta: { usuarios: true  }},

    //CITAS
    {path: '/miscitas',     name: 'miscitas',    component: ()=> import('@/views/crm/citas/miscitas.vue'),
    meta: { usuarios: true  }},
    {path: '/cita',  name: 'cita',  component:() => import('@/views/crm/citas/cita.vue'),
    meta: { usuarios: true  }},
    {path: '/catcitas',     name: 'catcitas',    component: ()=> import('@/views/crm/citas/catcitas.vue'),
    meta: { usuarios: true  }},

    //PROYECTOS
    {path: '/misprioridades',     name: 'misprioridades',    component: ()=> import('@/views/crm/proyectos/misprioridades.vue'),
    meta: { usuarios: true  }},
    {path: '/proyecto',  name: 'proyecto',  component:() => import('@/views/crm/proyectos/proyecto.vue'),
    meta: { usuarios: true  }},
    {path: '/catproyectos',     name: 'catproyectos',    component: ()=> import('@/views/crm/proyectos/catproyectos.vue'),
    meta: { usuarios: true  }},
    {path: '/misproyectos',     name: 'misproyectos',    component: ()=> import('@/views/crm/proyectos/misproyectos.vue'),
    meta: { usuarios: true  }},

    //TAREAS
    {path: '/mistareas',     name: 'mistareas',    component: ()=> import('@/views/crm/tareas/mistareas.vue'),
    meta: { usuarios: true  }},
    {path: '/cattareas',     name: 'cattareas',    component: ()=> import('@/views/crm/tareas/cattareas.vue'),
    meta: { usuarios: true  }},
    {path: '/tarea',     name: 'tareas',    component: ()=> import('@/views/crm/tareas/tarea.vue'),
    meta: { usuarios: true  }},

    //OPORTUNIDADES
    {path: '/misoportunidades',     name: 'misoportunidades',    component: ()=> import('@/views/crm/oportunidades/misoportunidades.vue'),
    meta: { usuarios: true  }},
    {path: '/oportunidad',     name: 'oportunidad',    component: ()=> import('@/views/crm/oportunidades/oportunidad.vue'),
    meta: { usuarios: true  }},
    {path: '/seguimiento',     name: 'seguimiento',    component: ()=> import('@/views/crm/oportunidades/seguimiento.vue'),
    meta: { usuarios: true  }},
    {path: '/catoportunidades',     name: 'catoportunidades',    component: ()=> import('@/views/crm/oportunidades/catoportunidades.vue'),
    meta: { usuarios: true  }},
    {path: '/seguimientooport',     name: 'seguimientooport',    component: ()=> import('@/views/crm/oportunidades/seguimientooport.vue'),
    meta: { usuarios: true  }},


    //SEGUIMIENTO OPORTUNIDADES

    //FASE DE GENERADA
    {path: '/generada',  name: 'generada',  component:() => import('@/views/crm/oportunidades/seguimientooport/generada/generada.vue'),
      meta: { usuarios: true  }},
    {path: '/agendarcita',  name: 'agendarcita',  component:() => import('@/views/crm/oportunidades/seguimientooport/generada/agendarcita.vue'),
      meta: { usuarios: true  }},

    //FASE DE PERFILADA
    {path: '/perfilada',  name: 'perfilada',  component:() => import('@/views/crm/oportunidades/seguimientooport/perfilada/perfilada.vue'),
      meta: { usuarios: true  }},
    {path: '/perfilpros',  name: 'perfilpros',  component:() => import('@/views/crm/oportunidades/seguimientooport/perfilada/perfilpros.vue'),
      meta: { usuarios: true  }},

    //FASE DE DEMO
    {path: '/demo',  name: 'demo',  component:() => import('@/views/crm/oportunidades/seguimientooport/demo/demo.vue'),
      meta: { usuarios: true  }},
    {path: '/reportar',  name: 'reportar',  component:() => import('@/views/crm/oportunidades/seguimientooport/demo/reportar.vue'),
      meta: { usuarios: true  }},
    {path: '/reportarcliente',  name: 'reportarcliente',  component:() => import('@/views/crm/oportunidades/seguimientooport/demo/reportarcliente.vue'),
      meta: { usuarios: true  }},
    {path: '/reportarprospecto',  name: 'reportarprospecto',  component:() => import('@/views/crm/oportunidades/seguimientooport/demo/reportarprospecto.vue'),
      meta: { usuarios: true  }},

    //FASE DE CIERRE
    {path: '/cierre',  name: 'cierre',  component:() => import('@/views/crm/oportunidades/seguimientooport/cierre/cierre.vue'),
      meta: { usuarios: true  }},
    {path: '/encuesta',  name: 'encuesta',  component:() => import('@/views/crm/oportunidades/seguimientooport/cierre/encuesta.vue'),
      meta: { usuarios: true  }},


    //RESULTADOS DE OPORTUNIDADES
    {path: '/ganadas',  name: 'ganadas',  component:() => import('@/views/crm/oportunidades/resultados/ganadas.vue'),
      meta: { usuarios: true  }},
    {path: '/perdidas',  name: 'perdidas',  component:() => import('@/views/crm/oportunidades/resultados/perdidas.vue'),
      meta: { usuarios: true  }},
    {path: '/pospuestas',  name: 'pospuestas',  component:() => import('@/views/crm/oportunidades/resultados/pospuestas.vue'),
      meta: { usuarios: true  }},


    //ACTIVIDADES
    {path: '/misactividades',     name: 'misactividades',    component: ()=> import('@/views/crm/actividades/misactividades.vue'),
      meta: { usuarios: true  }},
    {path: '/catactividades',     name: 'catactividades',    component: ()=> import('@/views/crm/actividades/catactividades.vue'),
      meta: { usuarios: true  }},
    {path: '/conactividades',     name: 'conactividades',    component: ()=> import('@/views/crm/actividades/conactividades.vue'),
      meta: { usuarios: true  }},
  
      {path: '/actividad',        name: 'actividad',       component: ()=> import('@/views/crm/actividades/actividad.vue'),
      meta: { usuarios: true  }},
 
    // // //Calendario
    {path: '/miagenda',     name: 'miagenda',    component: ()=> import('@/views/crm/agenda/miagenda.vue'),
      meta: { usuarios: true  }},
    {path: '/citalat',  name: 'citalat',  component:() => import('@/views/agenda/citalat.vue'),
      meta: { usuarios: true  }},

    // //Poliza
    {path: '/polizasopo',  name: 'polizasopo',  component:() => import('@/views/crm/poliza/polizasopo.vue'),
      meta: { usuarios: true  }},
    {path: '/poliza',  name: 'poliza',  component:() => import('@/views/crm/poliza/poliza.vue'),
      meta: { usuarios: true  }},

    //CONTACTO
    {path: '/catcontacto',  name: 'catcontacto',  component:() => import('@/views/crm/contactos/catcontacto.vue'),
      meta: { usuarios: true  }},
    {path: '/contacto',  name: 'contacto',  component:() => import('@/views/crm/contactos/contacto.vue'),
      meta: { usuarios: true  }},

    {path: '/midia',  name: 'midia',  component:() => import('@/views/crm/agenda/midia.vue'),
      meta: { usuarios: true , libre: true }},  
    {path: '/movercosas',  name: 'movercosas',  component:() => import('@/views/crm/agenda/movercosas.vue'),
      meta: { usuarios: true , libre: true }},  

    //CLIENTES
    {path: '/catclientes',  name: 'catclientes',  component:() => import('@/views/crm/clientes/catclientes.vue'),
      meta: { usuarios: true  }},
    {path: '/cliente',  name: 'cliente',  component:() => import('@/views/crm/clientes/cliente.vue'),
      meta: { usuarios: true  }},

      //CAT TIPOS ACT
      { path: '/cattipoact',  name: 'cattipoact',  component:() => import('@/views/crm/tipos/cattipoact/cattipoact.vue'),
      meta: { usuarios: true  }},
      {path: '/tipoact',  name: 'tipoact',  component:() => import('@/views/crm/tipos/cattipoact/tipoact.vue'),
      meta: { usuarios: true  }},

      //CAT PROBLEMAS
      {path: '/cattipoprob',  name: 'cattipoprob',  component:() => import('@/views/crm/tipos/cattipoprob/cattipoprob.vue'),
      meta: { usuarios: true  }},
      {path: '/tipoprob',  name: 'tipoprob',  component:() => import('@/views/crm/tipos/cattipoprob/tipoprob.vue'),
      meta: { usuarios: true  }},

      //CAT VENDEDORES
      {path: '/catvendedores',  name: 'catvendedores',  component:() => import('@/views/crm/vendedores/catvendedores.vue'),
      meta: { usuarios: true  }},
      {path: '/vendedor',  name: 'vendedor',  component:() => import('@/views/crm/vendedores/vendedor.vue'),
      meta: { usuarios: true  }},
    
      // //CAT TIPOS OPORTUNIDADES
      {path: '/cattipooport',  name: 'cattipooport',  component:() => import('@/views/crm/tipos/cattipooport/cattipooport.vue'),
      meta: { usuarios: true  }},
      {path: '/tipooport',  name: 'tipooport',  component:() => import('@/views/crm/tipos/cattipooport/tipooport.vue'),
      meta: { usuarios: true  }},
      {path: '/configuracion',  name: 'configuracion',  component:() => import('@/views/crm/tipos/configuracion.vue'),
      meta: { usuarios: true  }},


      //DOCUMENTOS
      {path: '/consultadoc',  name: 'consultadoc',  component:() => import('@/views/crm/documentos/consultadoc.vue'),
      meta: { usuarios: true  }},


      //CRM PRO

      {path: '/misclientes',  name: 'misclientes',  component:() => import('@/views/crmpro/misclientes/misclientes.vue'),
        meta: { usuarios: true  },
        children:[
          {path: '/datospro',  name: 'datospro',  component:() => import('@/views/crmpro/datos/datospro.vue'),
            meta: { usuarios: true  }},
          {path: '/estadisticaspro',  name: 'estadisticaspro',  component:() => import('@/views/crmpro/estadisticas/estadisticaspro.vue'),
            meta: { usuarios: true  }},
          {path: '/preparacionpro',  name: 'preparacionpro',  component:() => import('@/views/crmpro/preparacion/preparacionpro.vue'),
            meta: { usuarios: true  }},
          {path: '/agendarpro',  name: 'agendarpro',  component:() => import('@/views/crmpro/agendar/agendarpro.vue'),
            meta: { usuarios: true  }},
          {path: '/historialpro',  name: 'historialpro',  component:() => import('@/views/crmpro/historial/historialpro.vue'),
            meta: { usuarios: true  }},
        ]
      },
      {path: '/datos',  name: 'datos',  component:() => import('@/views/crmpro/datos/datos.vue'),
        meta: { usuarios: true  }},
      {path: '/mapa',  name: 'mapa',  component:() => import('@/views/crmpro/datos/mapa.vue'),
        meta: { usuarios: true  }},
      
      {path: '/ventaspormes',  name: 'ventaspormes',  component:() => import('@/views/crmpro/estadisticas/ventaspormes.vue'),
        meta: { usuarios: true  }},
      {path: '/saldos',  name: 'saldos',  component:() => import('@/views/crmpro/estadisticas/saldos.vue'),
        meta: { usuarios: true  }},

      {path: '/preparacion',  name: 'preparacion',  component:() => import('@/views/crmpro/preparacion/preparacion.vue'),
        meta: { usuarios: true  }},
      {path: '/oportunidades',  name: 'oportunidades',  component:() => import('@/views/crmpro/preparacion/oportunidades.vue'),
        meta: { usuarios: true  }},
      {path: '/pedidos',  name: 'pedidos',  component:() => import('@/views/crmpro/preparacion/pedidos.vue'),
        meta: { usuarios: true  }},

      {path: '/pendientes',  name: 'pendientes',  component:() => import('@/views/crmpro/agendar/pendientes.vue'),
        meta: { usuarios: true  }},
      {path: '/priorizar',  name: 'priorizar',  component:() => import('@/views/crmpro/agendar/priorizar.vue'),
        meta: { usuarios: true  }},

      {path: '/historialcliente',  name: 'historialcliente',  component:() => import('@/views/crmpro/historial/historialcliente.vue'),
        meta: { usuarios: true  }},







      
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })


  router.beforeEach( (to, from, next) => {
    
    if (localStorage.getItem('soporte')) {
      var cToken  = localStorage.soporte;
      next()
      // NO TIENE TOKEN. cHORIZO
    } else {
      // localStorage.clear();
      // var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjIyNTg4NjgsImlkIjoiYWRtaW4iLCJvcmlnX2lhdCI6MTU2MjI1NTI2OH0.SWGD5aH16otmX_cu-EaQ9OlgnLwV-WxbAuy85LUS1bE';
      // // localStorage.soporte  = JSON.stringify(token);
      // localStorage.setItem('soporte', token);
      // // next({path: '/'});
    }


    if(to.matched.some(record => record.meta.libre)){  
      
      next()
    } else if(store.state.token){

      if (to.matched.some(record => record.meta.ADMIN)){
      
        next()
      }
    } else {
      // console.log(from)
      next({
        name: 'login'
      })
    }


  })


export default router 


