export default{
  namespaced: true,
  state:{
    oportunidad: {
      asunto:'',
      avatarurl:'',
      celular:'',
      conocioen:'',
      creada:'',
      email:'',
      empresa:'',
      estatus:'',
      ganada:'',
      idcontacto:0,
      idoportunidad: 0,
      idtipo:0,
      importe:0.00,
      nomcont: '',
      numcli:'',
      numfact:'',
      obs:'',
      obsmejorar:'',
      probcierre:'',
      seguimiento:'',
      sigllamada:'',
      telefono:'',
      tipo:''
    },
  },

  mutations:{
    SET_OPORTUNIDAD (state, oportunidad){
      state.oportunidad = oportunidad
    },
  },

  actions:{
    setOportunidad ({commit}, oportunidad){
      commit('SET_OPORTUNIDAD', oportunidad)
    },
  },

  getters:{
    getOportunidad(state){
      return state.oportunidad
    },

  }
}