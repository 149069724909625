// import Vue from 'vue'
// import Vuex from 'vuex'
// import router from '@/router'

export default{
  namespaced: true,
  state:{
    nomuser:'',
    cita: {
      asunto:'',
      temas: '',
      fechastart: new Date().toISOString().substr(0, 10),
      fechaend: new Date().toISOString().substr(0, 10),
      idcontacto:0,
      idproy:0,
      idoportunidad:0
    },
  },

  mutations:{
    SET_CITA (state, citas){
      state.citas = citas
    },
  },

  actions:{
    setCita ({commit}, cita){
      commit('SET_CITA', cita)
    },
  },

  getters:{
    getCita(state){
      return state.cita
    },

  }
}