<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-navigation-drawer
      v-if="getLogeado == true"
      v-model="drawer"
      app
      dark
      color="primary"
    >
      <v-list dense>
        <v-card
          color="white"
          class="ma-0 pa-0"
        >
          <v-img
            src="soporte2.png"
            contain
            max-height="60px"
          />
        </v-card>

        <v-card
          elevation="0"
          class="mx-4 mt-2"
          color="primary"
        >
          {{ getdatosUsuario.nomuser }}
        </v-card>
        <!-- <v-card
          elevation="0"
          class="mx-4 mb-1"
          color="primary"
        >
          {{ getdatosUsuario.email }}
        </v-card> -->
        <v-divider />

        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :to="item.path"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            dense
          >
            <template
              v-slot:activator
              :to="item.path"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.path"
              link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon class="">
                  {{ child.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- DIRECTOS -->
          <v-list-item
            v-else
            :key="item.text"
            :to="item.path"
            :color="item.color"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icono2 }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-divider />

      <v-list-item
        dense
        color="primary"
        @click="salir"
      >
        <v-list-item-action>
          <v-icon color="white">
            exit_to_app
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="red--white">
            <h4>Salir</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </v-navigation-drawer>

    <v-app-bar
      v-if="getLogeado == true"
      app
      dark
      dense
      fixed
      class="primary"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>SAIT CRM</v-toolbar-title>
      <!-- <v-subheader>Tel: 81 1161 0225 </v-subheader> -->
      <v-spacer />

      <div class="text-center">
        <v-menu
          origin="center"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              v-bind="attrs"
              v-on="on"
            >
              Catálogos
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(catalogo, index) in catalogos"
              :key="index"
              :to="catalogo.path"
            >
              <v-list-item-title>
                <v-icon>{{ catalogo.icon }}</v-icon>
                {{ catalogo.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="text-center">
        <v-menu
          origin="center"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              v-bind="attrs"
              v-on="on"
            >
              Operaciones
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(operacion, index) in operaciones"
              :key="index"
              :to="operacion.path"
            >
              <v-list-item-title>
                <v-icon>{{ operacion.icon }}</v-icon>
                {{ operacion.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- class="hidden-sm-and-down" -->
      <!-- <v-btn
          text
          class=""
          dark
          :to="{name: 'panel'}"
        >
          <v-icon>mdi-calendar-multiple</v-icon>
        </v-btn> -->
      <v-btn
        text
        class=""
        dark
        :to="{ name: 'midia' }"
      >
        <v-icon>mdi-calendar-plus</v-icon>
      </v-btn>

      <v-btn
        text
        class=""
        dark
        :to="{ name: 'configuracion' }"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn
        text
        class=""
        dark
        :to="{ name: 'miperfil' }"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-btn
        text
        class=""
        dark
        @click="salir"
      >
        <v-icon>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-app-bar
      v-else
      app
      dark
      dense
      fixed
      color="primary"
    >
      <v-toolbar-title>SAIT CRM</v-toolbar-title>
      <!-- <v-subheader>Tel: 81 1161 0225 </v-subheader> -->
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <!-- <v-btn text dark :to="{name: 'calendario'}">Calendario de Cursos</v-btn> -->
        <v-btn
          text
          dark
          :to="{ name: 'login' }"
        >
          Login
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
        <snackbar />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import {  path } from '@amcharts/amcharts4/core'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    snackbar: require('@/components/Shared/Snackbar.vue').default
  },

  data: () => ({
    model1: false,
    model2: false,
    links: ['Home', 'About Us', 'Team', 'Services', 'Blog', 'Contact Us'],
    menus: [
      {
        icon: 'mdi-book-open',
        title: 'Registrar Actividad',
        path: 'actividad',
        color: 'teal'
      },
      {
        icon: 'mdi-order-bool-ascending-variant',
        title: 'Registrar una orden',
        color: 'teal'
      },
      {
        icon: 'mdi-hammer-wrench',
        title: 'Registrar Servicio',
        path: 'servicios.add',
        color: 'teal'
      },
      {
        icon: 'mdi mdi-calendar-plus',
        title: 'Agendar una Cita',
        path: 'cita',
        color: 'teal'
      }
    ],
    catalogos: [
      {
        icon: 'mdi-card-account-details',
        title: 'Contactos',
        path: 'catcontacto',
        color: 'red'
      },
      
      {
        icon: 'mdi-office-building-cog',
        title: 'Clientes',
        path: 'catclientes',
        color: 'purple'
      },
      {
        icon: 'mdi-account-tie',
        title: 'Vendedores',
        path: 'catvendedores',
        color: 'red'
      },
      {
        icon: 'mdi-account-search',
        title: 'Mis clientes',
        path: 'misclientes',
        color: 'red'
      },
      {
        icon: 'mdi-briefcase',
        title: 'Proyectos',
        path: 'catproyectos',
        color: 'red'
      },
      { icon: 'mdi-headset', title: 'Pólizas de soporte', path: 'polizasopo' }
    ],
    operaciones: [
      {
        icon: 'mdi-account-group',
        title: 'Citas',
        path: 'catcitas',
        color: 'red'
      },
      {
        icon: 'mdi-order-bool-ascending-variant',
        title: 'Órdenes',
        path: 'misordenes',
        color: 'red'
      },
      {
        icon: 'mdi-file-edit',
        title: 'Actividades',
        path: 'catactividades',
        color: 'red'
      },
      {
        icon: 'mdi-store',
        title: 'Oportunidades',
        path: 'catoportunidades',
        color: 'red'
      },
      {
        icon: 'mdi-book',
        title: 'Documentos',
        path: 'consultadoc',
        color: 'red'
      }
    ],

    items: [
      {
        icon: 'mdi-content-copy',
        text: 'Consultar Ordenes',
        path: 'conordenes'
      },
      {
        icon: 'mdi-book-edit-outline',
        text: 'Consultar Actividades',
        path: 'conactividades'
      },
      

      { icon: 'mdi-view-dashboard', text: 'Mi día', path: 'midia' },
      {
        icon: 'mdi-book-edit-outline',
        text: 'Nueva orden',
        path: 'addorden'
      },
      { icon: 'mdi-book-open', text: 'Mis ordenes', path: 'misordenes' },
    
      {
        icon: 'mdi-book-edit-outline',
        text: 'Registrar Actividad',
        path: 'Actividad'
      },
      { icon: 'mdi-book-open', text: 'Mis Actividades', path: 'catactividades' },
    
      {
        icon: 'mdi-book-edit-outline',
        text: 'Nueva cita',
        path: 'cita'
      },
      {
        icon: 'mdi-book-edit-outline',
        text: 'Nueva Oportunidad',
        path: 'oportunidad'
      },

      {
        icon: 'mdi-book-edit-outline',
        text: 'Nuevo Proyecto',
        path: 'proyecto'
      },


      { icon: 'mdi-chart-box', text: 'Estadisticas', path: 'graficas' },
      

      // {
      //   icon: 'mdi-chevron-up',
      //   'icon-alt': 'mdi-chevron-down',
      //   text: 'Agenda',
      //   icon2: 'mdi-account',
      //   text: 'Operaciones',
      //   model: false,
      //   children: [
      //     {
      //       icon: 'mdi mdi-card-account-details',
      //       text: 'Mi dia',
      //       path: 'midia'
      //     },
      //     { icon: 'mdi-clipboard', text: 'Panel dos', path: 'calendario' }
      //   ]
      // },

      // {
      //   icon: 'mdi-chevron-up',
      //   'icon-alt': 'mdi-chevron-down',
      //   text: 'CRM PRO',
      //   model: false,
      //   children: [
      //     {
      //       icon: 'mdi mdi-card-account-details',
      //       text: 'reportar',
      //       path: 'reportar'
      //     },
      //     { icon: 'mdi-clipboard', text: 'datos', path: 'seguimientooport' }
      //   ]
      // },

      { icon: 'mdi-cog', text: 'Configuración', path: 'configuracion' }
    ],

    drawer: true,
    snackbar: false,
    isPaste: false,
    show1: false,
    password: '',
    email: '',

    rules: {
      required: value => !!value || 'Requerido.',
      min: v => v.length >= 8 || 'Minimo 8 caracteres',
      emailMatch: () => 'El correo y/o la contraseña no son correctos'
    }
  }),

  computed: {
    ...mapGetters('login', ['getdatosUsuario', 'getLogeado']),

    // logueado(){
    //   return this.$store.state.usuario;
    // },

    esUsuario() {
      return this.getLogeado && this.getdatosUsuario.nivel == 'USER'
    },

    esAsesor() {
      return this.getLogeado && this.getdatosUsuario.nivel == 'ADMIN'
    }
  },

  created() {
    // console.log("Nivel 2",this.$store.state.nivel)
  },

  methods: {
    ...mapActions('login', ['validarUser', 'salirLogin']),

    ruta() {
      this.$router.push({ name: 'login' }).catch(() => {})
    },

    herramienta() {
      this.$router.push('https://anydesk.es/plataformas/windows')
    },

    salir() {
      this.$store.dispatch('salir')
      this.salirLogin()
    }
  }
}
</script>
