import Vue from 'vue'
// import store from '@/store'

export default{
  namespaced: true,
  state:{
    idusuariosweb: '',
    login: false,
    datosUsuario:'',
    nivel: ''
  },

  mutations:{
    LOGEADO(state, value){
      state.login = value
    },
    DATOS_USUARIO(state, datosUsuario){
      state.datosUsuario = datosUsuario
    },
    ID_USUARIO(state, idusuariosweb){
      state.idusuariosweb = idusuariosweb
    },

    NIVEL(state, nivel){
      state.nivel = nivel
    },

    SALIR(state){
      state.login = false
      state.datosUsuario = ''
      state.idusuariosweb =  ''
    }
  },
  actions:{
    salirLogin({commit}){
      commit('SALIR')
    },

    GetInfoUser({commit}, usuariologin){
      return new Promise((resolve, reject) => {
        //No se utiliza el this por que no esta dentro de un componente,
        // tampoco se pone el signo de pesos por que no es una variable global
        // Vue.http.post('auth/api/v1/buscarusuario',usuario).then(respuesta=>{

        console.log("buscarusuario", usuariologin)
        Vue.http.post('buscarusuario',usuariologin).then(respuesta=>{
          // console.log("datosUsuario",respuesta)
          if (respuesta.body !== "Sin resultados" ){
            commit('LOGEADO', true)
            commit('DATOS_USUARIO',respuesta.body.result)
            resolve(true)
          } else {
            resolve(false)
          }

        }, error => {
          resolve(false)
          console.log('error', error)
          reject(error)
        })
      })
    },

    validarUser( {commit}, usuariologin){
      console.log("usuario",usuariologin)
      return new Promise((resolve, reject) => {
        Vue.http.post('login', usuariologin).then(respuesta=>{

          return respuesta.json()
        }).then(respuestaJson=>{
          if(respuestaJson == null){
            resolve(false)
          }else{
            console.log("response", respuestaJson)
            resolve(respuestaJson)
          }
        }, error => {
          reject(error)
        })
      })
    },

    saveInfoUsuario( datosUsuario){
      Vue.http.put('auth/api/v1/usuarios/'+ datosUsuario.idusuariosweb, {
        'nomuser': datosUsuario.Nomuser,
        'email': datosUsuario.Email,
        'telefono': datosUsuario.Telefono,
        'celular': datosUsuario.Celular,
        'empresa': datosUsuario.Empresa,
        'departamento': datosUsuario.Departamento,
        'sucursal': datosUsuario.Sucursal
      })
        .then(function(){
          // var x = {email: datosUsuario.email, password: datosUsuario.password}
          // dispatch("validarUser",x)
        })
    }
  },

  getters:{
    getLogeado(state){
      return state.login
    },
    getdatosUsuario(state){
      return state.datosUsuario
    },

    getidUsuariosWeb(state){
      return state.idusuariosweb
    },

    getNivel(state){
      return state.nivel
    }
  }
}