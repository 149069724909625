import Vue from 'vue'
import Vuex from 'vuex'

import router from '@/router'
import VueJwtDecode from 'vue-jwt-decode'

import login      from '@/modules/Login/Login'
import Registro   from '@/modules/Login/Registro'

import miperfil   from '@/modules/Login/miperfil'
import ordenes    from '@/modules/ordenes/ordenesUsuarios'

import citas      from '@/modules/citas/citas'
import contactos  from '@/modules/contactos/contactos'
import clientes  from '@/modules/clientes/clientes'
import controles  from '@/modules/controles/controles'

import oportunidad  from '@/modules/oportunidad/oportunidad'
import proyectos  from '@/modules/proyectos/proyectos'


import snackbar from '@/modules/snackbar'

Vue.use(Vuex,VueJwtDecode)

export default new Vuex.Store({
  state: {
    usuario: '',
    token: null,
    nivel: null,
    drawer: true,
    menu: true,
    drawer2: false
  },

  mutations: {
    setToken(state, token){
      state.token = token
    },

    setUsuario (state, usuario){
      // console.log('serUsuario', usuario)
      state.usuario = usuario
    },
    setNivel (state, nivel){
      // console.log('setNivel', nivel)
      state.nivel =  nivel
    },

   

  },

  actions: {
    
   
    // Se manda llamar desde Login.vue
    guardarToken({commit},token){
      // Guardar token
      commit("setToken", token)
      // console.log("setoken", token)
      // Colocar Token en LocalStorage
      localStorage.setItem("soporte", token)

      //Decifrar Usuario
      var decode        = VueJwtDecode.decode(token)
      var UsuarioActivo = decode.id
      // Hacer commit para guardar Usuario
      // console.log("UsuarioActivo", UsuarioActivo)
      commit("setUsuario", UsuarioActivo)   // Decodifica el token para sacar usuario
    },

    guardarNivel({commit},nivel){
      commit('setNivel', nivel)

    },
    
    // autoLogin({commit}){
    // },

    salir({commit}){
      // localStorage.removeItem("token")
      commit("setUsuario", null)
      commit("setToken", '')
      localStorage.removeItem("soporte")
      //var tokenGen = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjIyNTg4NjgsImlkIjoiYWRtaW4iLCJvcmlnX2lhdCI6MTU2MjI1NTI2OH0.SWGD5aH16otmX_cu-EaQ9OlgnLwV-WxbAuy85LUS1bE';
      //localStorage.soporte  = JSON.stringify(tokenGen);
      router.push({name: 'login'})
    }
  },

  getters:{
    traeNomuser(state){
      return state.usuario
    },

    traeNivel(state){
      return state.nivel
    },

  
  },

  modules:{
    login,
    Registro,
    miperfil,
    ordenes,
    citas,
    snackbar,
    contactos,
    clientes,
    controles,
    oportunidad,
    proyectos,
  }

})






