<template>
  <v-row>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="6"
      xs="12"
      class="pa-2"
    >
      <v-card>
        <v-img
          src="home1.jpg"
        />
        <v-card-actions>
          <v-btn
            color="secondary"
            :to="{name: 'vista1'}"
          >
            Detalles
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="6"
      xs="12"
      class="pa-2"
    >
      <v-card>
        <v-img
          src="home3.jpg"
        />
        <v-card-actions>
          <v-btn
            color="primary"
            :to="{name: 'vista3'}"
          >
            Detalles
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="6"
      xs="12"
      class="pa-2"
    >
      <v-card>
        <v-img
          src="home2.jpg"
        />
        <v-card-actions>
          <v-btn
            color="accent"
            :to="{name: 'vista2'}"
          >
            Detalles
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    show: false
  })
}
</script>

